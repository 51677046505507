import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SpinnerTypes, Text } from 'wix-ui-tpa/cssVars';
import { SlotsStatus } from '../../../../../types/types';
import { NoAvailableSlotsViewModel } from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { SlotsPerDay } from '../../../../../utils/slotsPerDay/slotsPerDay';
import { st, classes } from './WeekAvailability.st.css';
import { DataHooks } from './constants';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import { DayAvailability } from './DayAvailability/DayAvailability';
import { WidgetLoader } from '../../WidgetLoader/WidgetLoader';
import { AvailabilityPlugin } from '../../AvailabilityPlugin/AvailabilityPlugin';

export type DayWithSlotsFactory<slotsviewmodel> = (props: {
  date: string;
  slotsViewModel: SlotsViewModel;
}) => React.ReactNode;

export type DayWithoutSlotsIndicationFactory<slotsviewmodel> = (props: {
  text: string;
  slotsViewModel: SlotsViewModel;
}) => React.ReactNode;

export interface WeekAvailabilityProps<slotsviewmodel> {
  slotsStatus: SlotsStatus;
  slotsPerDays: SlotsPerDay<slotsviewmodel>[];
  noAvailableSlotsViewModel: NoAvailableSlotsViewModel;
  dayWithSlotsFactory: DayWithSlotsFactory<slotsviewmodel>;
  dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<slotsviewmodel>;
  accessibleDays?: boolean;
  onKeyDown?:: (e: React.KeyboardEvent<htmldivelement>) => void;
}

const WeekAvailability = <slotsviewmodel extends="" object="">({
  slotsPerDays,
  slotsStatus,
  noAvailableSlotsViewModel,
  dayWithSlotsFactory,
  dayWithoutSlotsIndicationFactory,
  accessibleDays,
  onKeyDown,
}: WeekAvailabilityProps<slotsviewmodel>) => {
  const { isEditor } = useEnvironment();

  const NoAvailability = () => {
    return noAvailableSlotsViewModel &&
      slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS ? (
      <noavailableslots viewModel="{noAvailableSlotsViewModel!}"></noavailableslots>
    ) : null;
  };

  const Loader = () => {
    return slotsStatus === SlotsStatus.LOADING ? (
      <widgetloader spinnerType="{SpinnerTypes.slim}"></widgetloader>
    ) : null;
  };

  return (
    <div className="{classes.root}" data-hook="{DataHooks.WeekAvailability}" onKeyDown="{onKeyDown}">
      <div className="{classes.week}">
        {slotsPerDays?.map(
          (slotsPerDay: SlotsPerDay<slotsviewmodel>, index) => {
            const { date, slots, isToday, dayWithoutSlots, accessibility } =
              slotsPerDay;
            const isPastDate = !isEditor && slotsPerDay.isPastDate;

            return (
              <div 0="" className="{st(classes.dayWrapper," {="" isToday:="" !isEditor="" &&="" isToday,="" isPastDate,="" })}="" tabIndex="{accessibleDays" ?="" :="" undefined}="" data-hook="{`${DataHooks.DayAvailability}-${date}`}" data-dayindex="{index}" role="group" aria-label="{accessibility.dayDetailsAriaLabel}" key="{date}" id="{date}">
                <daydetails slotsPerDay="{slotsPerDay}"></daydetails>
                {slotsStatus !== SlotsStatus.LOADING && (
                  <dayavailability date="{date}" accessibility="{accessibility}" isPastDate="{isPastDate}" slots="{dayWithSlotsFactory({" date,="" slotsViewModel:="" slots,="" })}="" noSlots="{dayWithoutSlotsIndicationFactory({" text:="" dayWithoutSlots,=""></dayavailability>
                )}
              </div>
            );
          },
        )}
      </slotsviewmodel></div>
      <noavailability></noavailability>
      <loader></loader>
      <availabilityplugin></availabilityplugin>
    </div>
  );
};

interface DayDetailsProps<slotsviewmodel> {
  slotsPerDay: SlotsPerDay<slotsviewmodel>;
}

const DayDetails = <slotsviewmodel extends="" object="">({
  slotsPerDay,
}: DayDetailsProps<slotsviewmodel>) => {
  const { weekday, day } = slotsPerDay;

  return (
    <div aria-hidden="true" className="{classes.dayDetails}" data-hook="{DataHooks.DayDetails}">
      <text className="{classes.weekday}">{weekday}</text>
      <text className="{classes.day}">{day}</text>
    </div>
  );
};

export default WeekAvailability;
</slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel></htmldivelement></slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel></slotsviewmodel>